<template>
  <div style="background:#fff;">
    <div class="remind flex flex-center">
      <div>标题</div>
      <input type="text" placeholder="请填写" v-model="title" />
    </div>
    <div class="remind flex flex-center" @click="prizeVisible = true">
      <div>选择类型</div>
      <div>
        {{ text }}
        <img class="taskItem-icon" src="../assets/imgs/arrow.png" alt="" />
      </div>
    </div>
    <div class="rulesView">
      <div class="rulesView-title">上传附件</div>
      <img
        class="rulesView-add"
        src="../assets/imgs/add.png"
        @click="triggerUpload"
        v-if="add"
        alt=""
      />

      <div class="audio flex flex-center" v-if="!add && type == 'file'">
        <div class="audioItem flex flex-center">
          <img src="../assets/imgs/file.png" class="audioItem-icon" alt="" />
          <div>文件</div>
        </div>
      </div>
    </div>

    <input
      v-if="type == 'image'"
      id="filePhoto"
      type="file"
      ref="file"
      accept="image/*"
      hidden
      @change="onInputChange($event)"
    />
    <input
      v-if="type == 'video'"
      id="filePhoto"
      type="file"
      ref="file"
      accept="video/*"

      hidden
      @change="onInputChange($event)"
    />
    <input
      v-if="type == 'file'"
      id="filePhoto"
      type="file"
      ref="file"
      accept="file/*"
      hidden
      @change="onInputChange($event)"
    />

    <VuePicker
      :data="list"
      :showToolbar="true"
      @cancel="prizeVisible = false"
      @confirm="Prizeconfirm"
      :visible.sync="prizeVisible"
    />

    <div class="rightullidiv" v-if="!add && type == 'image'">
      <img :src="img_url" alt="" class="rightulliimg" />
    </div>

    <div class="rightullidiv" v-if="!add && type == 'video'">
      <video :src="img_url" alt="" class="rightulliimg"></video>
    </div>

    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-add flex-item_center" @click="push()">
        发布
      </div>
    </div>
  </div>
</template>

<script>
import { timingSafeEqual } from "crypto";
import VuePicker from "vue-pickers";
import axios from "axios";
import config from "@/config";
import { Toast } from "vant";
import * as request from "../api/data";
import _ from "lodash";

export default {
  components: { VuePicker },
  data() {
    return {
      prizeVisible: false,
      list: [
        [
          { label: "图片", value: "image" },
          { label: "视频", value: "video" },
          { label: "普通文件", value: "file" },
        ],
      ],
      add: true,
      type: "image",
      text: "图片",
      img_url: "",
      upload_url: "",
      title: "",
    };
  },
  methods: {
    async push() {
      let that = this;
      if (this.title == "") {
        Toast("请输入标题");
        return false;
      }
      if (this.img_url == "") {
        Toast("请上传附件");
        return false;
      }

      let res = await request._materialUpload({
        title: this.title,
        type: this.type,
        img_url: this.upload_url,
        userid: localStorage.getItem("userid"),
      });
      console.log("上传文件", res);
      if (_.toInteger(res.code) === 1) {
        Toast("发布成功");
        setTimeout(() => {
          that.$router.back();
        }, 1200);
      }else{
        Toast(res.msg)
      }
    },
    triggerUpload() {
      $("#filePhoto").click();
    },
    async onInputChange(el) {
      var that = this;
      var file = el.target.files[0];
      console.log(file);
      var type = file.type.split("/")[0];

      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", 1);
      console.log(formData);
      // let res = await request._uploadImg(formData);
      axios({
        method: "post",
        url: config.baseUrl.dev + "workapi/mobile.User/upload",
        data: formData,
      }).then(
        (res) => {
          console.log("Res", res);
          if (res.data.code == 1) {
            that.img_url = res.data.data.img_url;
            that.add = false;
            that.upload_url = res.data.data.upload_url;
          }
        },

        (err) => {}
      );
    },
    // 选择奖品
    Prizeconfirm(res) {
      console.log("res", res);
      this.text = res[0].label;
      this.type = res[0].value;
      // this.$set(this.postData[this.index], "prize_id", res[0].value);
    },
  },
};
</script>

<style scoped>
.rightullidiv {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
  padding-bottom: 260px;
}

.rightulliimg {
  max-width: 100%;
  max-height: 700px;
}

.remind {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  justify-content: space-between;
  color: #66728f;
}

.remind input {
  outline: none;
  border: 0;
  text-align: right;
}

.remind-icon {
  width: 12px;
  height: 20px;
  margin-left: 10px;
}

.remind-textarea {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
}

.remindText-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

textarea {
  outline: none;
  border: 0;
  width: 670px;
  margin-top: 38px;
  height: 110px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.rulesView {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
}

.rulesView-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin: 0 0 37px 0;
}

.rulesView-add {
  width: 180px;
  height: 180px;
  background: #ededf7;
  border-radius: 16px 16px 16px 16px;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

.taskItem-icon {
  width: 12px;
  height: 20px;
  margin-left: 8px;
}

.audio {
  width: 690px;
  height: 169px;
  margin: 16px auto;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.audioItem {
  width: 558px;
  height: 86px;
  background: #f4f5f7;
  border-radius: 16px 16px 16px 16px;
  margin-left: 30px;
}
.audioItem-icon {
  width: 50px;
  height: 50px;
  margin: 0 14px 0 17px;
}
</style>
